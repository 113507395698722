@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

/* Global Styles */

* {
  font-family: 'Open Sans', Arial, sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --dark-font-color: #222C44;
  --main-orange: #F0A393;
  --light-green: #00D68F;
  --orange-badge: #FFAA00;
  --button-dark: #454758;
  --button-red: #FF3D71;
  --dim-grey: #8F9CB2;
}

body {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: row;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Text */

h1 {
  font-size: 32px;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 20px;
}

h1, h2 {
  font-weight: lighter;
}

p {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

/* Hyperlink */

a {
  cursor: pointer;
  color: #1976d2;
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
}

/* Input */

input {
  font-size: 14px;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #BDBDBD;
}

label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
  display: block;
  text-transform: uppercase;
}

/* Button */
.button, button {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
  background-color: #1976d2;
  color: white;
  border: none;
}

.button:hover, button:hover {
  opacity: 0.8;
  font-weight: normal;
}

.button:disabled, button:disabled {
  opacity: 0.5;
  cursor: auto;
}

/* Fancy Button */

.fancy-button {
  background-color: white;
  color: #1976d2;
}

.fancy-button i.material-icons {
  color: #1976d2;
  padding-right: 4px;
}

/* Checkout Cart, Shipping Prices */

.cart-item, .shipping-item {
  width: 100%;
  min-width: 400px;
  max-width: 450px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 32px;
  margin-bottom: 8px;
  border-radius: 2px;
  background-color: #EEEEEE;
}

.table-thumbnail {
  display: inline-block;
  padding-right: 12px;
  max-height: 55px;

  img {
    border-radius: 5px;
    max-width: 72px;
    box-shadow:0px 0px 1px 1px rgba(0,0,0,0.40)
  }
}

.table-thumbnail-vertical {
  max-height: 140px
}

.scroll-modal {
  overflow: auto;
  max-height: 90%;
}

.confirm-modal {
}

.confirm-modal::before {
  content: '';
  position: absolute;
  top: -120rem;
  left: -120rem;
  right: -120rem;
  bottom: -120rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: -1;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);


}

.full-screen-modal {
  width: 100%;
}

.nb-theme-default nb-tabset .tab.disabled .tab-link {
  pointer-events: all;
}
.nb-theme-default nb-tabset .tab.disabled {
  pointer-events: all;
  :hover {
    color: rgba(143, 155, 179, .48);
    opacity: 1;
  }
}

.nb-theme-default .tooltip-inner {
  width: max-content;
  max-width: 100% !important;
  white-space: pre-wrap;
}

.nb-theme-default nb-card-body {
  position: static;
}

.emoji {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
